@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-contact {
    background-color: #00a19e;
    background-image: linear-gradient(to bottom, #98ca3c 0%, #3ab449 100%);
    text-align: center;
    position: relative;
    overflow: hidden;
    padding-top: 110px;
    padding-bottom: 110px;
    .container {
        h2, p {
            color: #ffffff;
        }
        h2 {
            font-family: 'Lato-Light';
            font-size: 2rem;
            line-height: 1.2;
            letter-spacing: .01em;
            margin-bottom: 2rem;
        }
        p {
            font-family: 'Lato-Light';
            font-size: 1.25rem;
            line-height: 1.35;
            margin-bottom: 2.25rem;
            a {
                color: #ffffff;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .btn {
            background-color: #ffffff;
            font-family: 'Lato-Bold';
            font-size: 1rem;
            line-height: 1.15em;
            letter-spacing: .005em;
            padding: 10px 35px 12px;
            border-radius: 0;
            color: $main-second-text-color;
            margin-bottom: 2rem;
        }
        .read-more {
            color: #ffffff;
            font-size: 1.15rem;
        }
        & > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-contact {
        .container {
            h2 {
                font-size: 2.5rem;
            }
            .btn {
                font-size: 1.0625rem;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-contact {
        .container {
            .btn {
                font-size: 1.1875rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-contact {
        padding-top: 140px;
        padding-bottom: 140px;
        .container {
            padding-left: 7%;
            padding-right: 7%;
            h2 {
                font-size: 3rem;
                margin-bottom: 2.5rem;
            }
            p {
                font-size: 1.5rem;
                margin-bottom: 2.75rem;
            }
            .read-more {
                font-size: 1.35rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-contact {
        .container {
            .btn {
                font-size: 1.35rem;
            }
        }
    }
}
