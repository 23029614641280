@font-face {
    font-family: 'CeraPro-Black';
    src: url('/fonts/CeraPro-Black.eot');
    src: url('/fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Black.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-BlackItalic';
    src: url('/fonts/CeraPro-BlackItalic.eot');
    src: url('/fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-BlackItalic.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-Bold';
    src: url('/fonts/CeraPro-Bold.eot');
    src: url('/fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Bold.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-BoldItalic';
    src: url('/fonts/CeraPro-BoldItalic.eot');
    src: url('/fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-BoldItalic.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-Italic';
    src: url('/fonts/CeraPro-Italic.eot');
    src: url('/fonts/CeraPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Italic.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-Light';
    src: url('/fonts/CeraPro-Light.eot');
    src: url('/fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Light.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-LightItalic';
    src: url('/fonts/CeraPro-LightItalic.eot');
    src: url('/fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-LightItalic.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-Medium';
    src: url('/fonts/CeraPro-Medium.eot');
    src: url('/fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Medium.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-MediumItalic';
    src: url('/fonts/CeraPro-MediumItalic.eot');
    src: url('/fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-MediumItalic.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-Regular';
    src: url('/fonts/CeraPro-Regular.eot');
    src: url('/fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Regular.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-Thin';
    src: url('/fonts/CeraPro-Thin.eot');
    src: url('/fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-Thin.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'CeraPro-ThinItalic';
    src: url('/fonts/CeraPro-ThinItalic.eot');
    src: url('/fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CeraPro-ThinItalic.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Gotham-Book';
    src: url('/fonts/Gotham-Book.eot');
    src: url('/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Gotham-Book.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans-Regular';
    src: url('/fonts/BentonSans-Regular.eot');
    src: url('/fonts/BentonSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Regular.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans-Bold';
    src: url('/fonts/BentonSans-Bold.eot');
    src: url('/fonts/BentonSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Bold.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans-Black';
    src: url('/fonts/BentonSans-Black.eot');
    src: url('/fonts/BentonSans-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Black.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans-Medium';
    src: url('/fonts/BentonSans-Medium.eot');
    src: url('/fonts/BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Medium.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans-Book';
    src: url('/fonts/BentonSans-Book.eot');
    src: url('/fonts/BentonSans-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Book.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Calibre-Bold';
    src: url("/fonts/Calibre-Bold.eot");
    src: url("/fonts/Calibre-Bold.eot?#iefix") format('embedded-opentype'),
        url("/fonts/Calibre-Bold.woff2") format('woff2'),
        url("/fonts/Calibre-Bold.woff") format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Calibre-Semibold';
    src: url("/fonts/Calibre-Semibold.eot");
    src: url("/fonts/Calibre-Semibold.eot?#iefix") format('embedded-opentype'),
        url("/fonts/Calibre-Semibold.woff2") format('woff2'),
        url("/fonts/Calibre-Semibold.woff") format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'Calibre-Medium';
    src: url("/fonts/Calibre-Medium.eot");
    src: url("/fonts/Calibre-Medium.eot?#iefix") format('embedded-opentype'),
        url("/fonts/Calibre-Medium.woff2") format('woff2'),
        url("/fonts/Calibre-Medium.woff") format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Calibre-Regular';
    src: url("/fonts/Calibre-Regular.eot");
    src: url("/fonts/Calibre-Regular.eot?#iefix") format('embedded-opentype'),
        url("/fonts/Calibre-Regular.woff2") format('woff2'),
        url("/fonts/Calibre-Regular.woff") format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Black';
    src: url('/fonts/Lato-Black.eot');
    src: url('/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Lato-Black.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Bold';
    src: url('/fonts/Lato-Bold.eot');
    src: url('/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Lato-Bold.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Medium';
    src: url('/fonts/Lato-Medium.eot');
    src: url('/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Lato-Medium.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Regular';
    src: url('/fonts/Lato-Regular.eot');
    src: url('/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Lato-Regular.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Semibold';
    src: url('/fonts/Lato-Semibold.eot');
    src: url('/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Lato-Semibold.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lato-Light';
    src: url('/fonts/Lato-Light.eot');
    src: url('/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Lato-Light.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'FontAwesome-Bold';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("/fonts/FontAwesome-Bold.eot");
    src: url("/fonts/FontAwesome-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/FontAwesome-Bold.woff") format("woff");
}
@font-face {
    font-family: 'FontAwesome-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("/fonts/FontAwesome-Regular.eot");
    src: url("/fonts/FontAwesome-Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/FontAwesome-Regular.woff") format("woff");
}
@font-face {
    font-family: 'ogn-nushield';
    src:  url('/fonts/nushield.eot?dmq2nr');
    src:  url('/fonts/nushield.eot?dmq2nr#iefix') format('embedded-opentype'),
      url('/fonts/nushield.woff?dmq2nr') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'ogn-nushield-arrows';
    src:  url('/fonts/ogn-nushield-arrows.eot?dmq2nr');
    src:  url('/fonts/ogn-nushield-arrows.eot?dmq2nr#iefix') format('embedded-opentype'),
      url('/fonts/ogn-nushield-arrows.woff?dmq2nr') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
.icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'FontAwesome-Regular' !important;
    &.font-weight-bold {
        font-family: 'FontAwesome-Bold' !important;
    }
    &.icon-arrow-down:before {
        content: "\f0d7";
    }
    &.icon-arrow-up:before {
        content: "\f0d8";
    }
    &.icon-info-circle:before {
        content: "\f05a";
    }
    &.icon-user {
        font-family: 'ogn-nushield' !important;
        line-height: 20px;
        &:before {
            content: "\e90b";
        }
    }
    &.icon-envelope {
        font-family: 'ogn-nushield' !important;
        line-height: 20px;
        &:before {
            content: "\e908";
        }
    }
    &.icon-menu {
        font-family: 'ogn-nushield' !important;
        line-height: 20px;
        &:before {
            content: "\e909";
        }
    }
    &.icon-close {
        font-family: 'ogn-nushield' !important;
        line-height: 20px;
        &:before {
            content: "\e907";
        }
    }
    &.icon-products {
        font-family: 'ogn-nushield' !important;
        line-height: 20px;
        &:before {
            content: "\e90a";
        }
    }
    &.icon-player-pause:before {
        font-family: 'ogn-nushield' !important;
        content: "\e900";
    }
    &.icon-player-play:before {
        font-family: 'ogn-nushield' !important;
        content: "\e901";
    }
    &.icon-player-volume-on:before {
        font-family: 'ogn-nushield' !important;
        content: "\e902";
    }
    &.icon-player-volume-off:before {
        font-family: 'ogn-nushield' !important;
        content: "\e903";
    }
    &.icon-player-replay:before {
        font-family: 'ogn-nushield' !important;
        content: "\e904";
    }
}