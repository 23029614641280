@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../css/variables';
$padding-top-desktop: 120px;
$padding-bottom-desktop: 120px;
$padding-top-mobile: 90px;
$padding-bottom-mobile: 90px;
.template-subpage {
    .component-breadcrumb + .component-content {
        padding-top: 60px;
    }
    .component-before-contact {
        padding-bottom: 60px;
    }
    .component-alt-bg {
        margin-top: 30px;
        padding-top: 60px;
    }
}
