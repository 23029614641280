@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import 'variables';
a sup {
    display: inline-block;
    text-decoration: none;
}
.shadow-top {
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        height: 10px;
        box-shadow: 0 1px 20px rgba(0,0,0,.2);
    }
}
.shadow-bottom {
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 10px;
        box-shadow: 0 -1px 20px rgba(0,0,0,.2);
    }
}
.btn {
    border-radius: 0;
    color: $main-link-color;
    font-family: 'BentonSans-Medium';
    padding: .65rem 1.35rem .5rem;
    &:focus {
        box-shadow: none;
    }
}
.btn-red {
    background-color: $main-second-text-color;
    color: white;
    border: 2px solid transparent;

    &:hover {
        color: $main-link-color;
        background-color: white;
        border: 2px solid $main-second-text-color;
    }
}
.text-red {
    color: $main-second-text-color;
}
.bg-white {
    background-color: #ffffff;
}
.bg-gray {
    background-color: #f8f8f8;
}
.mw-25 {
    max-width: 25% !important;
}
.mw-50 {
    max-width: 50% !important;
}
.mw-75 {
    max-width: 75% !important;
}
.text-break {
    word-break: break-all !important;
    overflow-wrap: break-word !important;
}
@keyframes read-more-animation {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
}
@keyframes download-animation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
}
.download, .read-more {
    display: inline-block;
    font-family: 'Lato-Bold';
    color: $main-link-color;
    text-decoration: none;
    font-size: 1rem;
    white-space: nowrap;
    span {
        white-space: normal;
    }
    &:hover {
        color: $main-link-color;
        text-decoration: none;
        span {
            text-decoration: underline;
        }
    }
}
.read-more {
    &:after {
        content: '\003E';
        padding-left: .35rem;
        display: inline-block;
        transform: translateX(0);
        transition: transform 1.3s ease-out;
    }
    &:hover {
        &:after {
            animation-timing-function: ease-out;
            animation-duration: 1.3s;
            animation-iteration-count: infinite;
            animation-name: read-more-animation;
        }
    }
}
.download {
    &:after {
        content: '\e90c';
        font-family: 'ogn-nushield';
        padding-left: .35rem;
        display: inline-block;
    }
    &:hover {
        &:after {
            animation-timing-function: ease;
            animation-duration: 1.3s;
            animation-iteration-count: infinite;
            animation-name: download-animation;
        }
    }
}
.footnote {
    display: block;
    font-family: 'Lato-Light';
    line-height: 1.45em;
    a {
        color: $main-text-color;
        text-decoration: underline;
        &:hover {
            color: $main-text-color;
            text-decoration: none;
        }
    }
    &.with-sup {
        padding-left: .5em;
        margin-left: 0em;
        position: relative;
        sup:first-child {
            position: absolute;
            left: 0;
            top: .5em;
            font-size: 1em;
        }
    }
}
.br-desktop {
    display: inline;
    &:before {
        content: ' ';
    }
}
.br-mobile {
    display: block;
    height: 0;
    overflow: hidden;
    &:before {
        content: none;
    }
}
.table-heading {
    font-family: 'Lato-Light';
    background-image: linear-gradient(to bottom, #98ca3e, #3ab44a);
    color: #ffffff;
    font-size: 1.375rem;
    padding: .5em 1em .6em;
    text-align: center;
}
@include media-breakpoint-up(sm) {
    .mw-sm-25 {
        max-width: 25% !important;
    }
    .mw-sm-50 {
        max-width: 50% !important;
    }
    .mw-sm-75 {
        max-width: 75% !important;
    }
    .mw-sm-100 {
        max-width: 100% !important;
    }
    .read-more, .download {
        font-size: 1.0625rem;
    }
}
@include media-breakpoint-up(md) {
    .mw-md-25 {
        max-width: 25% !important;
    }
    .mw-md-50 {
        max-width: 50% !important;
    }
    .mw-md-75 {
        max-width: 75% !important;
    }
    .mw-md-90 {
        max-width: 90% !important;
    }
    .mw-md-100 {
        max-width: 100% !important;
    }
    .read-more, .download {
        font-size: 1.1875rem;
    }
    .table-heading {
        font-size: 1.5625rem;
    }
    .br-mobile {
        display: inline;
        &:before {
            content: ' ';
        }
    }
}
@include media-breakpoint-up(lg) {
    .mw-lg-25 {
        max-width: 25% !important;
    }
    .mw-lg-40 {
        max-width: 40% !important;
    }
    .mw-lg-50 {
        max-width: 50% !important;
    }
    .mw-lg-60 {
        max-width: 60% !important;
    }
    .mw-lg-75 {
        max-width: 75% !important;
    }
    .mw-lg-90 {
        max-width: 90% !important;
    }
    .mw-lg-100 {
        max-width: 100% !important;
    }
    .br-desktop {
        display: block;
        height: 0;
        overflow: hidden;
        &:before {
            content: none;
        }
    }
}
@include media-breakpoint-up(xl) {
    .mw-xl-25 {
        max-width: 25% !important;
    }
    .mw-xl-50 {
        max-width: 50% !important;
    }
    .mw-xl-75 {
        max-width: 75% !important;
    }
    .mw-xl-90 {
        max-width: 90% !important;
    }
    .mw-xl-100 {
        max-width: 100% !important;
    }
    .read-more, .download {
        font-size: 1.35rem;
    }
}
