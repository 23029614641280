$topper-height: 32px;

$navigation-height-mobile: 100px;
$navigation-height-mobile-small: 80px;
$navigation-height-general-mobile: 100px;
$navigation-height-general-mobile-small: 80px;
$navigation-height-desktop: 195px;
$navigation-height-desktop-small: 80px;
$navigation-height-products-desktop: 55px;
$navigation-height-products-desktop-small: 0px;
$navigation-height-general-desktop: 140px;
$navigation-height-general-desktop-small: 80px;

$navigation-color: #64666b;

$products-nav-main-color: #ffffff;
$products-nav-main-text-color: #44474b;
$products-nav-second-text-color: #64666b;
$products-nav-borders-color: #d7d7d7;

$general-nav-main-color: #26a035;
$general-nav-main-transparent-color: rgba(38,160,53,.93);
$general-nav-main-text-color-desktop: #64666b;
$general-nav-main-text-color-mobile: #ffffff;
$general-nav-second-text-color: #ffffff;

$main-text-color: #64666b;
$main-second-text-color: #3ab449;
$main-link-color: #3ab449;
$main-third-text-color: #e63d30;
$main-second-tab-text-color: #b2b2b2;

$template-home-text-color: #454649;
$component-alt-bg-color: #f4f7f9;

$content-padding-top-desktop: 30px;
$content-padding-bottom-desktop: 30px;
$content-padding-top-mobile: 30px;
$content-padding-bottom-mobile: 30px;

$select-variants-height: 200px;

$footer-bg-deep-blue: #041c2c;
$footer-bg-dark-blue: #002855;
$footer-color: #fff;