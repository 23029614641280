@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';
.component-intro {
    .container {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 30px;
        h2 {
            font-family: 'Calibre-Semibold';
            font-size: 1.35rem;
            line-height: 1.25em;
            margin-bottom: 1.5rem;
            color: $main-text-color;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-intro {
        .container {
            padding-top: 70px;
            padding-bottom: 20px;
            h2 {
                font-size: 1.5rem;
                line-height: 1.45em;
                margin-bottom: 2rem;
            }
        }
    }
}
