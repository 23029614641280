@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-content {
    padding-top: $content-padding-top-mobile;
    padding-bottom: $content-padding-bottom-mobile;
    .container {
        & > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-content {
        padding-top: $content-padding-top-desktop;
        padding-bottom: $content-padding-bottom-desktop;
    }
}
