@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../css/variables';
$padding-top-desktop: 120px;
$padding-bottom-desktop: 120px;
$padding-top-mobile: 90px;
$padding-bottom-mobile: 90px;
.template-home {
    height: 100%;
    body {
        height: 100%;
        .parallax-wrapper {
            height: 100%;
            section:not(.component-references):not(.component-sign-up):not(.component-contact) {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-family: 'Lato-Light';
                    font-weight: normal;
                    line-height: 1.2;
                    color: $main-second-text-color;
                    sup {
                        font-size: 60%;
                        top: -.75em;
                        line-height: 1em;
                        font-family: 'Lato-Light';
                    }
                }
                h2, h3 {
                    font-size: 1.625rem;
                    margin-bottom: .75em;
                }

                p {
                    font-size: 1rem;
                    b, strong {
                        font-family: 'Lato-Medium';
                        font-weight: normal;
                    }
                    a {
                        color: inherit;
                        text-decoration: underline;

                        &:hover {
                            color: inherit;
                            text-decoration: none;
                        }
                    }
                }

                ul {
                    padding-left: 1em;
                    margin-bottom: 1.25rem;
                    max-width: 100%;
                    font-size: 1rem;

                    li {
                        margin-bottom: 0.375em;
                    }
                }
                small {
                    font-size: .75rem;
                }
            }
            section.component-jumbotron {
                margin-top: $navigation-height-mobile;
                background-image: none;
                background-color: #ffffff;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 60vw;
                    background-image: url(/images/background-pattern.webp), linear-gradient(to bottom, #98ca3c, #3ab449);
                    background-position: 0% 0%;
                    background-size: 100px 100px, cover;
                    background-repeat: repeat, no-repeat;
                    width: 50%;
                    left: 0;
                    top: 0;
                    z-index: 0;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 60vw;
                    height: auto;
                    box-shadow: 0 -1px 20px rgba(0,0,0,.2);
                    z-index: 0;
                }
                .container {
                    position: relative;
                    z-index: 1;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    .heading-holder {
                        width: 50%;
                        height: 60vw;
                        h1 {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            z-index: 1;
                            margin: 0;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-start;
                            span {
                                display: block;
                                position: relative;
                                color: #ffffff;
                                &:first-child {
                                    font-family: 'Lato-Light';
                                    font-size: 4.255vw;
                                    line-height: 1em;
                                    bottom: -1.85em;
                                }
                                &:nth-child(2) {
                                    font-family: 'Lato-Light';
                                    font-size: 4.255vw;
                                    line-height: 1em;
                                    bottom: -2em;
                                }
                                &:nth-child(3) {
                                    font-family: 'Lato-Semibold';
                                    font-size: 9.25vw;
                                    line-height: 1em;
                                    bottom: -1em;
                                    left: -.03em;
                                }
                                &:last-child {
                                    font-family: 'Lato-Bold';
                                    font-size: 37vw;
                                    line-height: 1em;
                                    bottom: -0.12em;
                                    letter-spacing: -.025em;
                                    left: -.08em;
                                }
                            }
                        }
                    }
                    .image-holder {
                        width: 50%;
                        height: 60vw;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-image: url(/images/background-jumbotron-mobile.webp);
                        background-position: right -12vw bottom -10%;
                        background-repeat: no-repeat;
                        background-size: 115% auto;
                    }
                    .callout-holder {
                        width: 100%;
                        padding-top: $content-padding-top-mobile;
                        padding-bottom: $content-padding-bottom-mobile;
                        text-align: center;

                        z-index: 2;
                        .animated-element {
                            margin: 0 0 1rem;
                            max-width: 100%;
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            section.component-intro {
                background-color: #eff0f0;
                background-image: url(/images/background-pattern.webp);
                background-position: 0% 0%;
                background-size: 100px 100px;
                background-repeat: repeat;
                .container {
                    padding-top: $padding-top-mobile;
                    padding-bottom: $padding-bottom-mobile;
                    h2, p, a {
                        opacity: 0;
                        transform: translateY(-4px);
                        transition: opacity 1s ease-out, transform 1s ease-out;
                    }
                    h2 {
                        color: $main-second-text-color;
                    }
                }
                &.visible {
                    .container {
                        h2, p, a {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }
            }
            section.component-animated-columns {
                background-color: #ffffff;
                position: relative;
                overflow: hidden;
                .headings-holder {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    background-image: linear-gradient(to bottom, #98ca3c, #3ab449);
                    background-size: cover;
                    overflow: hidden;
                    z-index: 0;
                    h2 {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 33%;
                        left: 15px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        & > span {
                            color: #ffffff;
                            display: block;
                            line-height: 1em;
                            position: relative;
                            &:first-child {
                                font-family: 'Lato-Medium';
                                font-size: 5.4vw;
                            }
                            &:nth-child(2) {
                                font-family: 'Lato-Bold';
                                font-size: 27vw;
                                left: -.07em;
                                top: -.15em;
                                letter-spacing: -.07em;
                            }
                            &.fade-in {
                                position: relative;
                                font-size: 5.4vw;
                                top: -1.25em;
                                span {
                                    &:first-child,
                                    &:last-child {
                                        font-size: inherit;
                                        font-family: 'Lato-Medium';
                                        position: absolute;
                                        transition: opacity .3s ease-in .2s;
                                        top: 0;
                                        left: 0;
                                    }
                                    &:first-child {
                                        opacity: 1;
                                    }
                                    &:last-child {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        left: 33%;
                        background-color: #ffffff;
                    }
                }
                .container {
                    position: relative;
                    z-index: 1;
                    right: 0;
                    height: 100%;
                    .contents-holder {
                        height: 200%;
                        padding-left: calc(33% + 10px);
                        transition: transform .5s ease-in;
                        .contents-item {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            overflow: hidden;
                            & > * {
                                margin-bottom: 1.5rem;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                &.inverted {
                    .headings-holder {
                        background-image: none;
                        background-color: #ffffff;
                        h2 {
                            & > span {
                                &:first-child {
                                    color: #98ca3c;
                                }
                                &:nth-child(2) {
                                    color: #3ab449;
                                }
                                &.fade-in {
                                    span {
                                        color: #3ab449;
                                    }
                                }
                            }
                        }
                        &:after {
                            background-image: linear-gradient(to bottom, #98ca3c, #3ab449);
                            background-size: cover;
                            background-color: transparent;
                        }
                    }
                    .container {
                        .contents-holder {
                            .contents-item {
                                & > * {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
                &.next {
                    .headings-holder {
                        h2 {
                            span {
                                &.fade-in {
                                    span {
                                        &:first-child {
                                            opacity: 0;
                                        }
                                        &:last-child {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .container {
                        .contents-holder {
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            section.component-contact {
                padding: 0;
                .container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    * {
                        max-width: 100%;
                    }
                }
            }
            section.component-circle-of-care {
                padding-top: $padding-top-mobile;
                padding-bottom: $padding-bottom-mobile;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .template-home {
        body {
            .parallax-wrapper {
                section:not(.component-references):not(.component-sign-up):not(.component-contact) {
                    h2, h3 {
                        font-size: 2rem;
                    }
                    p {
                        font-size: 1.0625rem;
                    }
                    ul, ol {
                        font-size: 1.0625rem;
                    }
                }
                section.component-jumbotron {
                    .container {
                        .heading-holder {
                            height: 324px;
                            h1 {
                                span {
                                    &:first-child {
                                        font-size: 1.4015625rem;
                                    }
                                    &:nth-child(2) {
                                        font-size: 1.4015625rem;
                                    }
                                    &:nth-child(3) {
                                        font-size: 3.046875rem;
                                    }
                                    &:last-child {
                                        font-size: 12.1875rem;
                                    }
                                }
                            }
                        }
                        .image-holder {
                            height: 324px;
                            background-position: right -5px bottom -15%;
                            background-size: 100% auto;
                        }
                        .callout-holder {
                            p {
                                font-size: 1.5rem;
                            }
                        }
                    }
                    &:before {
                        height: 324px;
                    }
                    &:after {
                        top: 324px;
                    }
                }
                section.component-animated-columns {
                    .headings-holder {
                        h2 {
                            width: 180px;
                            left: calc(50% - 255px);
                            & > span {
                                &:first-child {
                                    font-size: 1.85rem;
                                }
                                &:nth-child(2) {
                                    font-size: 9.25rem;
                                }
                                &.fade-in {
                                    font-size: 1.85rem;
                                }
                            }
                        }
                        &:after {
                            left: calc(50% - 90px);
                        }
                    }
                    .container {
                        .contents-holder {
                            padding-left: calc(33% + 30px);
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .template-home {
        body {
            .parallax-wrapper {
                section:not(.component-references):not(.component-sign-up):not(.component-contact) {
                    h2, h3 {
                        font-size: 2.25rem;
                    }
                    p {
                        font-size: 1.1875rem;
                    }
                    ul, ol {
                        font-size: 1.1875rem;
                    }
                }
                section.component-jumbotron {
                    .container {
                        .heading-holder {
                            height: 432px;
                            h1 {
                                span {
                                    &:first-child {
                                        font-size: 1.86875rem;
                                    }
                                    &:nth-child(2) {
                                        font-size: 1.86875rem;
                                    }
                                    &:nth-child(3) {
                                        font-size: 4.0625rem;
                                    }
                                    &:last-child {
                                        font-size: 16.25rem;
                                    }
                                }
                            }
                        }
                        .image-holder {
                            height: 432px;
                            background-position: right -15px bottom -20%;
                            background-size: 100% auto;
                        }
                    }
                    &:before {
                        height: 432px;
                    }
                    &:after {
                        top: 432px;
                    }
                }
                section.component-animated-columns {
                    .headings-holder {
                        h2 {
                            width: 240px;
                            left: calc(50% - 360px);
                            & > span {
                                &:first-child {
                                    font-size: 2.77rem;
                                }
                                &:nth-child(2) {
                                    font-size: 13.85rem;
                                }
                                &.fade-in {
                                    font-size: 2.77rem;
                                }
                            }
                        }
                        &:after {
                            left: calc(50% - 120px);
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .template-home {
        body {
            .parallax-wrapper {
                section:not(.component-references):not(.component-sign-up):not(.component-contact) {
                    h2, h3 {
                        font-size: 2.35rem;
                    }
                }
                section.component-jumbotron {
                    margin-top: $navigation-height-desktop-small;
                    overflow: hidden;
                    .container {
                        display: block;
                        .heading-holder {
                            height: 100%;
                            h1 {
                                height: 100%;
                                span {
                                    &:first-child {
                                        font-size: 2.1875rem;
                                        bottom: -1.4em;
                                    }
                                    &:nth-child(2) {
                                        font-size: 2.1875rem;
                                        bottom: -1.7em;
                                    }
                                    &:nth-child(3) {
                                        font-size: 4.125rem;
                                        bottom: -1.3em;
                                    }
                                    &:last-child {
                                        font-size: 21.75rem;
                                    }
                                }
                            }
                        }
                        .image-holder {
                            background-size: 480px 372px;
                            background-image: url(/images/background-jumbotron-desktop.webp);
                            background-position: right 80%;
                            width: 50vw;
                            height: 100%;
                            left: 50%;
                            right: auto;
                        }
                        .callout-holder {
                            position: absolute;
                            width: 50%;
                            bottom: 0;
                            right: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            padding: 0 5% 8rem;
                            text-align: left;
                            z-index: initial;
                            opacity: 0;
                            transition: opacity .3s ease-in;
                            p {
                                font-size: 1.5rem;
                                border-left: 2px solid #96c93d;
                                margin: .5rem 0;
                                padding: .5rem 0 .5rem 1.5rem;
                            }
                            & > *:last-child {
                                margin: .5rem 0 !important;
                            }
                            &.visible {
                                opacity: 1;
                            }
                        }
                    }
                    &:after {
                        display: none;
                    }
                    &:before {
                        height: 100%;
                        background-size: 200px 200px, cover;
                    }
                }
                section.component-intro {
                    background-size: 200px 200px;
                    .container {
                        padding-top: $padding-top-desktop;
                        padding-bottom: $padding-bottom-desktop;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        text-align: left;
                        h2 {
                            flex-grow: 0;
                            flex-shrink: 0;
                            padding-right: 2rem;
                            margin-bottom: 0 !important;
                            width: 35%;
                        }
                        .content-holder {
                            border-left: 2px solid #96c93d;
                            flex-grow: 1;
                            flex-shrink: 1;
                            align-self: stretch;
                            padding: .5rem 0 .5rem 2rem;
                        }
                    }
                }
                section.component-animated-columns {
                    .headings-holder {
                        h2 {
                            width: 320px;
                            left: calc(50% - 480px);
                            & > span {
                                &:first-child {
                                    font-size: 3.63rem;
                                }
                                &:nth-child(2) {
                                    font-size: 18.15rem;
                                }
                                &.fade-in {
                                    font-size: 3.63rem;
                                }
                            }
                        }
                        &:after {
                            left: calc(50% - 160px);
                        }
                    }
                }
                section.component-circle-of-care {
                    padding-top: $padding-top-desktop;
                    padding-bottom: $padding-bottom-desktop;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .template-home {
        body {
            .parallax-wrapper {
                section:not(.component-references):not(.component-sign-up):not(.component-contact) {
                    p {
                        font-size: 1.35rem;
                    }
                    ul, ol {
                        font-size: 1.35rem;
                    }
                }
                section.component-intro {
                    .container {
                        h2 {
                            width: 30%;
                        }
                    }
                }
                section.component-jumbotron {
                    .container {
                        .heading-holder {
                            h1 {
                                span {
                                    &:first-child {
                                        font-size: 2.375rem;
                                        bottom: -2em;
                                    }
                                    &:nth-child(2) {
                                        font-size: 2.375rem;
                                        bottom: -2.3em;
                                    }
                                    &:nth-child(3) {
                                        font-size: 5.125rem;
                                        bottom: -1.3em;
                                    }
                                    &:last-child {
                                        font-size: 26.25rem;
                                    }
                                }
                            }
                        }
                        .image-holder {
                            background-position: right 80%;
                            background-size: 580px 449px;
                        }
                        .callout-holder {
                            padding: 0 5% 10rem;
                            p {
                                font-size: 1.75rem;
                            }
                        }
                    }
                }
                section.component-animated-columns {
                    .headings-holder {
                        h2 {
                            left: calc(50% - 570px);
                            width: 376px;
                            padding-top: 5rem;
                            & > span {
                                &:first-child {
                                    font-size: 4.23rem;
                                }
                                &:nth-child(2) {
                                    font-size: 21.15rem;
                                }
                                &.fade-in {
                                    font-size: 4.23rem;
                                }
                            }
                        }
                        &:after {
                            left: calc(50% - 194px);
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1480px) {
    .template-home {
        body {
            .parallax-wrapper {
                section.component-jumbotron {
                    .container {
                        .image-holder {
                            background-size: 700px 542px;
                            background-position: right 90%;
                        }
                    }
                }
            }
        }
    }
}
@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    .template-home {
        body {
            .parallax-wrapper {
                section.component-animated-columns.inverted {
                    .headings-holder {
                        h2 {
                            & > span {
                                &:nth-child(2) {
                                    background: -webkit-linear-gradient(-90deg, #98ca3c, #3bb449);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .template-home {
            body {
                .parallax-wrapper {
                    section.component-intro {
                        .container {
                            h2 {
                                background: -webkit-linear-gradient(-90deg, #98ca3c, #3bb449);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
